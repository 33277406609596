import React, {useState, useEffect} from "react";
import SearchResultPage from "../../../templates/offplan-results-template";
import { UserObjectStoreProvider, isAuthenticated, getUserobjectData } from "@myaccount/website-service";

const SearchResultResiSalesWrap = (props) => {

    // For myaccount
    useEffect(() => {
        if (authUser) {
          const getUserObjects = async () => {
              try {
                  const userObjects = await getUserobjectData()
                  setUserObjects(userObjects.data.data)
              } catch (e) {
                  console.log("e:", e)
              }
          }
          getUserObjects()
        }
    }, [])

    const [userObjects, setUserObjects] = useState({});
    const authUser = isAuthenticated()

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    //console.log('aaaaaaaaaa', fullpathone)
    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/off-plan-properties/for-sale/" 
                pcategorytype={['building']}
                multipcategorytype = {['building']}
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype={[]}
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                psearch="Sales"
                userObjects={userObjects}
            />
        </React.Fragment>
    );
};

const SearchResultResiSales = (props) => {
    return(
        <UserObjectStoreProvider>
            <SearchResultResiSalesWrap {...props} />
        </UserObjectStoreProvider>
    )
}

export default SearchResultResiSales;